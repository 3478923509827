import Widget from "./widget";

import IconSVG from "./icon.svg";

const defaultProps = {
  treeNodes: [
    {
      id: "1",
      label: "Direct Messages",
      children: [
        { id: "2", label: "Alice" },
        { id: "3", label: "Bob" },
        {
          id: "4",
          label: "Charlie",
          children: [
            { id: "5", label: "Lukas" },
            {
              id: "6",
              label: "Barbie",
              children: [
                { id: "7", label: "Angela" },
                { id: "8", label: "John" },
              ],
            },
          ],
        },
      ],
    },
    {
      id: "9",
      label: "Direct Messages",
    },
    {
      id: "10",
      label: "Direct Messages",
    },
    {
      id: "11",
      label: "Direct Messages",
    },
    {
      id: "12",
      label: "Direct Messages",
    },
    {
      id: "13",
      label: "Direct Messages",
    },
    {
      id: "14",
      label: "Direct Messages",
    },
    {
      id: "15",
      label: "Direct Messages",
    },
    {
      id: "16",
      label: "Direct Messages",
    },
    {
      id: "17",
      label: "Direct Messages",
    },
    {
      id: "18",
      label: "Direct Messages",
    },
  ],
};

const defaultStyleProps = {
  treeHeight: "600",
  searchTerm: "",
  selectedIds: [],
  treeWidth: "242",
};

export const CONFIG = {
  type: Widget.getWidgetType(),
  name: "SpreadTree",
  iconSVG: IconSVG,
  needsMeta: true,
  isCanvas: false,
  defaults: {
    ...defaultProps,
    ...defaultStyleProps,
    widgetName: "SpreadTree",
    rows: 60,
    columns: 15,
    version: 1,
  },
  properties: {
    derived: Widget.getDerivedPropertiesMap(),
    default: Widget.getDefaultPropertiesMap(),
    meta: Widget.getMetaPropertiesMap(),
    config: Widget.getPropertyPaneConfig(),
    contentConfig: Widget.getPropertyPaneContentConfig(),
    styleConfig: Widget.getPropertyPaneStyleConfig(),
    stylesheetConfig: Widget.getStylesheetConfig(),
    autocompleteDefinitions: Widget.getAutocompleteDefinitions(),
  },
};

export default Widget;
