import React from "react";

export const ArrowRight: React.FC = () => {
  return (
    <svg
      fill="none"
      height="12"
      viewBox="0 0 8 12"
      width="8"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M7.18004 5.39143L2.03718 0.248571C1.8829 0.0942857 1.66861 0 1.42861 0C0.957186 0 0.571472 0.385714 0.571472 0.857143C0.571472 1.09714 0.665758 1.31143 0.820043 1.46571L5.3629 6L0.828615 10.5343C0.665758 10.6886 0.571472 10.9029 0.571472 11.1429C0.571472 11.6143 0.957186 12 1.42861 12C1.66861 12 1.8829 11.9057 2.03718 11.7514L7.18004 6.60857C7.33432 6.45429 7.42861 6.24 7.42861 6C7.42861 5.76 7.33432 5.54571 7.18004 5.39143Z"
        fill="#090707"
        fillRule="evenodd"
      />
    </svg>
  );
};
