import type { ViewerContext3D } from "@spread-ai/softy-renderer-react";
import type {
  BackgroundClickListener,
  NodeClickListener,
} from "@spread-ai/softy-renderer-react/dist/types/model/interfaces/ViewerWithClickableNodesAndBackground";
import type { ContextMenuRequestListener } from "@spread-ai/softy-renderer-react/dist/types/model/interfaces/ViewerWithContextMenu";
import type { ViewProjectionMatrixUpdateListener } from "@spread-ai/softy-renderer-react/dist/types/model/interfaces/ViewerWithControlableCamera";
import type { ModelAddedListener } from "@spread-ai/softy-renderer-react/dist/types/model/interfaces/ViewerWithModelsAddition";
import type { SelectedNodesUpdateListener } from "@spread-ai/softy-renderer-react/dist/types/model/interfaces/ViewerWithSelectableNodes";
import type { FlatFourByFourMatrix, NodeId } from "./types";

interface ViewerEventHandlerMap {
  nodeclick: NodeClickListener;
  selectionchange: SelectedNodesUpdateListener;
  backgroundclick: BackgroundClickListener;
  requestcontextmenu: ContextMenuRequestListener;
  viewchange: ViewProjectionMatrixUpdateListener;
  modeladded: ModelAddedListener;
}

export class ViewerApi {
  constructor(private viewerContext: ViewerContext3D) {}

  getViewer(): ViewerContext3D {
    return this.viewerContext;
  }

  removeNode(nodeId: string | string[]) {
    if (Array.isArray(nodeId)) {
      return this.viewerContext.removeNodes(nodeId);
    }
    return this.viewerContext.removeNode(nodeId);
  }

  isNodeExists(nodeId: string) {
    return this.viewerContext.isNodeExists(nodeId);
  }

  addModel(modelUrl: string) {
    return this.viewerContext.addModel(modelUrl);
  }

  setNodeEnabled(nodeId: NodeId | NodeId[], enabled: boolean) {
    if (Array.isArray(nodeId)) {
      return nodeId.map((id) => this.viewerContext.setNodeVisible(id, enabled));
    }
    return this.viewerContext.setNodeVisible(nodeId, enabled);
  }

  async duplicateSubtree(nodeId: string, targetParentId?: string) {
    const newSubtree = await this.viewerContext.duplicateSubtree(
      nodeId,
      targetParentId,
    );
    return newSubtree;
  }

  addEventListener = <GEventName extends keyof ViewerEventHandlerMap>(
    eventName: GEventName,
    handler: ViewerEventHandlerMap[GEventName],
  ) => {
    if (eventName === "selectionchange") {
      const handlerId = this.viewerContext.addSelectedNodesUpdateListener(
        handler as SelectedNodesUpdateListener,
      );

      const removeListener = () => {
        this.viewerContext.removeSelectedNodesUpdateListener(handlerId);
      };

      return removeListener;
    }

    if (eventName === "viewchange") {
      const handlerId =
        this.viewerContext.addViewProjectionMatrixUpdateListener(
          handler as ViewProjectionMatrixUpdateListener,
        );

      const removeListener = () => {
        this.viewerContext.removeViewProjectionMatrixUpdateListener(handlerId);
      };

      return removeListener;
    }

    if (eventName === "backgroundclick") {
      const handlerId = this.viewerContext.addBackgroundClickListener(
        handler as BackgroundClickListener,
      );

      const removeListener = () => {
        this.viewerContext.removeBackgroundClickListener(handlerId);
      };

      return removeListener;
    }

    if (eventName === "nodeclick") {
      const handlerId = this.viewerContext.addNodeClickListener(
        handler as NodeClickListener,
      );

      const removeListener = () => {
        this.viewerContext.removeNodeClickListener(handlerId);
      };

      return removeListener;
    }

    if (eventName === "requestcontextmenu") {
      const handlerId = this.viewerContext.addContextMenuRequestListener(
        handler as ContextMenuRequestListener,
      );

      const removeListener = () => {
        this.viewerContext.removeContextMenuRequestListener(handlerId);
      };

      return removeListener;
    }

    if (eventName === "modeladded") {
      const handlerId = this.viewerContext.addModelAddedListener(
        handler as ModelAddedListener,
      );

      const removeListener = () => {
        this.viewerContext.removeModelAddedListener(handlerId);
      };

      return removeListener;
    }

    throw new Error(
      `addEventListener() Error: Unknown event name: ${eventName}`,
    );
  };

  getNodeParent = (nodeId: NodeId) => {
    return this.viewerContext.getNodeParent(nodeId);
  };

  getNodeChildren(nodeId: NodeId) {
    return this.viewerContext.getNodeChildren(nodeId);
  }

  getSubtree(nodeId: NodeId) {
    return this.viewerContext.getSubtree(nodeId);
  }

  setNodeLocalTransform(
    nodeId: NodeId | NodeId[],
    transform: Float32Array | FlatFourByFourMatrix,
  ) {
    if (Array.isArray(nodeId)) {
      return nodeId.map((id) =>
        this.viewerContext.setNodeLocalTransform(id, transform),
      );
    }
    return this.viewerContext.setNodeLocalTransform(nodeId, transform);
  }

  setNodeLabel(nodeId: NodeId | NodeId[], label: string) {
    if (Array.isArray(nodeId)) {
      return nodeId.map((id) => this.viewerContext.setNodeLabel(id, label));
    }
    return this.viewerContext.setNodeLabel(nodeId, label);
  }

  setNodeColor(nodeId: NodeId | NodeId[], color: string) {
    if (Array.isArray(nodeId)) {
      return nodeId.map((id) => this.viewerContext.setNodeColor(id, color));
    }
    return this.viewerContext.setNodeColor(nodeId, color);
  }

  setNodeClickable(nodeId: NodeId | NodeId[], pickable: boolean) {
    if (Array.isArray(nodeId)) {
      nodeId.map((id) => this.viewerContext.setNodeClickable(id, pickable));
      return;
    }
    return this.viewerContext.setNodeClickable(nodeId, pickable);
  }

  setNodeSelectable(nodeId: NodeId | NodeId[], selectable: boolean) {
    if (Array.isArray(nodeId)) {
      nodeId.forEach((id) =>
        this.viewerContext.setNodeSelectable(id, selectable),
      );
      return;
    }
    return this.viewerContext.setNodeSelectable(nodeId, selectable);
  }

  projectPointToCanvas(coordinates: number[]) {
    const canvasCoords = this.viewerContext.projectPointToCanvas({
      x: coordinates[0],
      y: coordinates[1],
      z: coordinates[2],
    }) || { x: 0, y: 0 };

    return { left: canvasCoords.x, top: canvasCoords.y } as const;
  }
}
