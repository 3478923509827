import React from "react";
import type { WidgetProps, WidgetState } from "widgets/BaseWidget";
import BaseWidget from "widgets/BaseWidget";
import { Diagram } from "../components";
import { getDiagramData } from "../components/context";
import type {
  PathContainer,
  SignalPathConnection,
  SignalPathTerminal,
} from "../components/models/SignalPath";
import { expandRawSignalPaths } from "../components/models/SignalPath";
import { ValidationTypes } from "../../../constants/WidgetValidation";

export interface DiagramWidgetProps extends WidgetProps {
  terminals: SignalPathTerminal[];
  connections: SignalPathConnection[];
  paths: PathContainer[];

  hasMiniMap: boolean;
  scale: string;
  hasToolbar: boolean;
}

class DiagramWidget extends BaseWidget<DiagramWidgetProps, WidgetState> {
  constructor(props: DiagramWidgetProps) {
    super(props);
  }

  getDiagramData() {
    return getDiagramData(
      expandRawSignalPaths({
        terminals: this.props.terminals,
        paths: this.props.paths,
        connections: this.props.connections,
      }).signalPaths,
      null,
      null,
      null,
    );
  }

  static getPropertyPaneStyleConfig() {
    return [
      {
        sectionName: "General",
        children: [
          {
            propertyName: "hasMiniMap",
            label: "Minimap",
            helpText: "Mini preview of the diagram",
            controlType: "SWITCH",
            defaultValue: true,
            isBindProperty: false,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            propertyName: "hasToolbar",
            label: "Toolbar",
            helpText: "Toolbar helping in interactions with diagram",
            controlType: "SWITCH",
            defaultValue: false,
            isBindProperty: false,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            propertyName: "scale",
            label: "Diagram scale",
            helpText: "Scale for diagram visualization",
            placeholderText: "Enter positive number",
            controlType: "INPUT_TEXT",
            defaultValue: "1",
            isBindProperty: true,
            isTriggerProperty: true,
          },
        ],
      },
    ];
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "General",
        children: [
          {
            helperText: "Defines connections of the diagram.",
            propertyName: "connections",
            label: "Connections",
            controlType: "INPUT_TEXT",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.ARRAY,
              params: {
                unique: ["id"],
                children: {
                  type: ValidationTypes.OBJECT,
                  params: {
                    allowedKeys: [
                      {
                        name: "color",
                        type: ValidationTypes.ARRAY,
                        children: {
                          type: ValidationTypes.OBJECT,
                          params: {
                            children: {
                              type: ValidationTypes.OBJECT,
                              params: {
                                allowedKeys: [
                                  {
                                    name: "hexCode",
                                    type: ValidationTypes.TEXT,
                                  },
                                  {
                                    name: "name",
                                    type: ValidationTypes.TEXT,
                                  },
                                ],
                              },
                            },
                          },
                        },
                      },
                      {
                        name: "crossSectionArea",
                        type: ValidationTypes.NUMBER,
                      },
                      {
                        name: "id",
                        type: ValidationTypes.TEXT,
                      },
                      {
                        name: "origin",
                        type: ValidationTypes.TEXT,
                      },
                      {
                        name: "partNumber",
                        type: ValidationTypes.TEXT,
                      },
                      {
                        name: "segmentFiles",
                        type: ValidationTypes.ARRAY,
                        params: {
                          children: {
                            type: ValidationTypes.TEXT,
                          },
                        },
                      },
                      {
                        name: "signalName",
                        type: ValidationTypes.TEXT,
                      },
                      {
                        name: "wireLength",
                        type: ValidationTypes.NUMBER,
                      },
                      {
                        name: "wireType",
                        type: ValidationTypes.TEXT,
                      },
                    ],
                  },
                },
              },
            },
          },

          {
            helperText: "Defines paths of the diagram.",
            propertyName: "paths",
            label: "Paths",
            controlType: "INPUT_TEXT",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.ARRAY,
              params: {
                children: {
                  type: ValidationTypes.OBJECT,
                  params: {
                    allowedKeys: [
                      {
                        name: "path",
                        type: ValidationTypes.ARRAY,
                        params: {
                          children: { type: ValidationTypes.TEXT },
                        },
                      },
                    ],
                  },
                },
              },
            },
          },
          {
            helperText: "Defines terminals of the diagram.",
            propertyName: "terminals",
            label: "Terminals",
            controlType: "INPUT_TEXT",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.ARRAY,
              params: {
                unique: ["id"],
                children: {
                  type: ValidationTypes.OBJECT,
                  params: {
                    allowedKeys: [
                      {
                        name: "componentReferenceId",
                        type: ValidationTypes.TEXT,
                      },
                      {
                        name: "componentType",
                        type: ValidationTypes.TEXT,
                      },
                      {
                        name: "connectionSignalName",
                        type: ValidationTypes.TEXT,
                      },
                      {
                        name: "diagnosisId",
                        type: ValidationTypes.TEXT,
                      },
                      {
                        name: "fuseValue",
                        type: ValidationTypes.NUMBER,
                      },
                      {
                        name: "connectorColor",
                        type: ValidationTypes.OBJECT,
                        params: {
                          allowedKeys: [
                            {
                              name: "hexCode",
                              type: ValidationTypes.TEXT,
                            },
                            {
                              name: "name",
                              type: ValidationTypes.TEXT,
                            },
                          ],
                        },
                      },
                      {
                        name: "connectorName",
                        type: ValidationTypes.TEXT,
                      },
                      {
                        name: "connectorNameAdditional",
                        type: ValidationTypes.TEXT,
                      },
                      {
                        name: "connectorPartNumber",
                        type: ValidationTypes.TEXT,
                      },
                      {
                        name: "contactSurface",
                        type: ValidationTypes.TEXT,
                      },
                      {
                        name: "coordinates",
                        type: ValidationTypes.ARRAY,
                        params: {
                          children: {
                            type: ValidationTypes.OBJECT,
                            params: {
                              allowedKeys: [
                                {
                                  name: "coordinate",
                                  type: ValidationTypes.ARRAY,
                                  params: {
                                    children: { type: ValidationTypes.TEXT },
                                  },
                                },
                              ],
                            },
                          },
                        },
                      },
                      {
                        name: "description",
                        type: ValidationTypes.TEXT,
                      },
                      {
                        name: "id",
                        type: ValidationTypes.TEXT,
                      },
                      {
                        name: "partNumber",
                        type: ValidationTypes.TEXT,
                      },
                      {
                        name: "terminalName",
                        type: ValidationTypes.TEXT,
                      },
                    ],
                  },
                },
              },
            },
          },
        ],
      },
    ];
  }

  getPageView() {
    return (
      <div style={{ width: "100%", height: "100%" }}>
        <Diagram
          hasMiniMap={this.props.hasMiniMap}
          hasToolbar={this.props.hasToolbar}
          linkDataArray={this.getDiagramData().links}
          nodeDataArray={this.getDiagramData().nodes}
          scale={parseFloat(this.props.scale)}
        />
      </div>
    );
  }

  static getWidgetType(): string {
    return "DIAGRAM_WIDGET";
  }
}

export default DiagramWidget;
