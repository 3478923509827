import React from "react";
import type { WidgetProps, WidgetState } from "widgets/BaseWidget";
import BaseWidget from "widgets/BaseWidget";
import { Tree } from "../components/Tree";
import type { TreeNode } from "../components/Node";
import { ValidationTypes } from "../../../constants/WidgetValidation";
import type { AutocompletionDefinitions } from "../../constants";
import {
  DefaultAutocompleteDefinitions,
  WidgetContainerDiff,
} from "../../WidgetUtils";
import type { DerivedPropertiesMap } from "../../../utils/WidgetFactory";
export interface SpreadTreeWidgetProps extends WidgetProps {
  treeNodes: TreeNode[];
  searchTerm: string;
  selectedIds: string[];
}

class SpreadTreeWidget extends BaseWidget<SpreadTreeWidgetProps, WidgetState> {
  constructor(props: SpreadTreeWidgetProps) {
    super(props);
    this.state = {
      selectedIds: props.selectedIds,
    };
  }
  static getAutocompleteDefinitions(): AutocompletionDefinitions {
    return {
      selectedIds: {
        "!type": "[string]",
        "!doc": "The array of selected nodes ids of the tree",
      },
      isVisible: DefaultAutocompleteDefinitions.isVisible,
    };
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "Data",
        children: [
          {
            helpText:
              "Allows users to select multiple nodes. Id must be unique",
            propertyName: "treeNodes",
            label: "TreeNodes",
            controlType: "INPUT_TEXT",
            placeholderText: "Enter tree nodes values",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.NESTED_OBJECT_ARRAY,
              params: {
                unique: ["id"],
                default: [],
                children: {
                  type: ValidationTypes.OBJECT,
                  params: {
                    allowedKeys: [
                      {
                        name: "label",
                        type: ValidationTypes.TEXT,
                        params: {
                          default: "",
                          required: true,
                        },
                      },
                      {
                        name: "id",
                        type: ValidationTypes.TEXT,
                        params: {
                          default: "",
                        },
                      },
                      {
                        name: "children",
                        type: ValidationTypes.ARRAY,
                        required: false,
                        params: {
                          children: {
                            type: ValidationTypes.OBJECT,
                            params: {
                              allowedKeys: [
                                {
                                  name: "label",
                                  type: ValidationTypes.TEXT,
                                  params: {
                                    default: "",
                                    required: true,
                                  },
                                },
                                {
                                  name: "id",
                                  type: ValidationTypes.TEXT,
                                  params: {
                                    default: "",
                                  },
                                },
                              ],
                            },
                          },
                        },
                      },
                    ],
                  },
                },
              },
            },
          },
          {
            helpText: "Search term of the tree",
            propertyName: "searchTerm",
            label: "Search term",
            controlType: "INPUT_TEXT",
            placeholderText: "Enter search term",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
        ],
      },

      {
        sectionName: "Events",
        children: [
          {
            helpText: "when a user selects node in tree",
            propertyName: "onSelectionChange",
            label: "onSelectionChange",
            controlType: "ACTION_SELECTOR",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: true,
          },
        ],
      },
    ];
  }
  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {
      value: `{{this.selectedIds}}`,
    };
  }
  static getMetaPropertiesMap(): Record<string, any> {
    return {
      selectedIds: [],
      isDirty: false,
    };
  }

  onSelectionChange = (value: string[]) => {
    this.props.updateWidgetMetaProperty("selectedIds", value);
    if (!this.props.isDirty) {
      this.props.updateWidgetMetaProperty("isDirty", true);
    }
  };

  static getPropertyPaneStyleConfig() {
    return [];
  }

  getPageView() {
    const { componentHeight, componentWidth } = this.getComponentDimensions();

    return (
      <Tree
        height={componentHeight - WidgetContainerDiff}
        initialData={this.props.treeNodes}
        onSelectionChange={(idsMap) => {
          const ids = Array.from(idsMap.values()).map((node) => node.id);
          this.onSelectionChange(ids);
        }}
        searchTerm={this.props.searchTerm}
        width={componentWidth - WidgetContainerDiff}
      />
    );
  }

  static getWidgetType(): string {
    return "SPREAD_TREE_WIDGET";
  }
}

export default SpreadTreeWidget;
