import * as go from "gojs";

import {
  GraphWidgetHierarchicalModeDirection,
  GraphWidgetHierarchicalModeLayering,
  GraphWidgetMode,
} from "../widget/types";

const getDirectionValue = (
  hierarchicalModeDirection: GraphWidgetHierarchicalModeDirection,
) => {
  switch (hierarchicalModeDirection) {
    case GraphWidgetHierarchicalModeDirection.Right:
      return 0;
    case GraphWidgetHierarchicalModeDirection.Down:
      return 90;
    case GraphWidgetHierarchicalModeDirection.Left:
      return 180;
    case GraphWidgetHierarchicalModeDirection.Up:
      return 270;
  }
};

const getLayeringOption = (
  hierarchicalModeLayering: GraphWidgetHierarchicalModeLayering,
) => {
  switch (hierarchicalModeLayering) {
    case GraphWidgetHierarchicalModeLayering.LongestPathSource:
      return go.LayeredDigraphLayout.LayerLongestPathSource;
    case GraphWidgetHierarchicalModeLayering.LongestPathSink:
      return go.LayeredDigraphLayout.LayerLongestPathSink;
    case GraphWidgetHierarchicalModeLayering.OptimalLinkLength:
      return go.LayeredDigraphLayout.LayerOptimalLinkLength;
  }
};

export const getDiagramLayout = (
  mode: GraphWidgetMode,
  hierarchicalModeDirection: GraphWidgetHierarchicalModeDirection,
  hierarchicalModeLayering: GraphWidgetHierarchicalModeLayering,
  hierarchicalModeLayerSpacing: number,
  hierarchicalModeColumnSpacing: number,
  hierarchicalModeAlignOption: number,
): go.Layout => {
  const $ = go.GraphObject.make;

  if (mode === GraphWidgetMode.Hierarchical) {
    const staticLayoutProps = {
      cycleRemoveOption: go.LayeredDigraphLayout.CycleDepthFirst,
      packOption: 7,
      initializeOption: go.LayeredDigraphLayout.InitDepthFirstOut,
      aggressiveOption: go.LayeredDigraphLayout.AggressiveLess,
      setsPortSpots: true,
    };

    const direction = getDirectionValue(hierarchicalModeDirection);
    const layeringOption = getLayeringOption(hierarchicalModeLayering);

    return $(go.LayeredDigraphLayout, {
      ...staticLayoutProps,
      direction,
      layeringOption,
      layerSpacing: hierarchicalModeLayerSpacing,
      columnSpacing: hierarchicalModeColumnSpacing,
      alignOption: hierarchicalModeAlignOption,
    }) as go.Layout;
  }

  // if (mode === GraphWidgetMode.ForceDirected) --- default
  return $(
    go.ForceDirectedLayout, // automatically spread nodes apart
    {
      maxIterations: 200,
      defaultSpringLength: 40,
      defaultElectricalCharge: 100,
    },
  ) as go.Layout;
};
