import { pick } from "ramda";
import type { SignalPathArray, SignalPathItem } from "../types";
import { isTerminal } from "../guards";

const searchProperties = [
  "terminalName",
  "connectorName",
  "componentReferenceId",
  "description",
  "connectorPartNumber",
  "signalName",
  "diagnosisId",
  "connectorNameAdditional",
];

const findKeywordInValuesByProperties = (
  keyword: string,
  signalPathItem: SignalPathItem,
  connectorNamePrefix: string,
): boolean => {
  const searchTargets = Object.values(pick(searchProperties, signalPathItem));

  if (isTerminal(signalPathItem)) {
    searchTargets.push(
      signalPathItem.connectorColor?.name ?? "",
      `${connectorNamePrefix}${signalPathItem.connectorName}`,
    );
  }

  return [searchTargets].some((value) =>
    value.toString().toUpperCase().includes(keyword.toUpperCase()),
  );
};

export const isSearchTermDefined = (keyword: string): boolean =>
  Boolean(keyword && keyword.trim().length);

export const filterSignalPathsByKeyword = (
  keyword: string,
  signalPaths: SignalPathItem[][],
  connectorNameTranslation: string,
): SignalPathArray[] => {
  if (!isSearchTermDefined(keyword)) {
    return signalPaths;
  }
  return signalPaths.filter((path) =>
    path.some((item) =>
      findKeywordInValuesByProperties(keyword, item, connectorNameTranslation),
    ),
  );
};
