import { ValidationTypes } from "constants/WidgetValidation";

import type { Stylesheet } from "entities/AppTheming";

export type GeneralStyles = {
  hasToolbar: boolean;
  hasNavigationCube: boolean;
};

export type ModelStyles = {
  isModelXrayed: boolean;
};

export type MarkersStyles = {
  selectedMarkerShapeColor: string;
  selectedMarkerDigitColor: string;
  selectedMarkerScale: number;
  unselectedMarkerShapeColor: string;
  unselectedMarkerDigitColor: string;
  unselectedMarkerScale: number;
};

export type StyleProps = {
  generalStyles: GeneralStyles;
  markerStyles: MarkersStyles;
  modelStyles: ModelStyles;
};

const UNSELECTED_MARKER_DEFAULT_SCALE = 4;
const SELECTED_MARKER_DEFAULT_SCALE = 8;

const markerThemes = {
  // orange
  selected: {
    shape: "#FF673C",
    digit: "#FFFFFF",
    scale: SELECTED_MARKER_DEFAULT_SCALE,
  },
  // yellow
  highlighted: {
    shape: "#FCC12B",
    digit: "#000000",
    scale: UNSELECTED_MARKER_DEFAULT_SCALE,
  },
  // gray
  unselected: {
    shape: "#303D55",
    digit: "#FFFFFF",
    scale: UNSELECTED_MARKER_DEFAULT_SCALE,
  },
};

const defaultStylesheet: StyleProps = {
  generalStyles: {
    hasToolbar: true,
    hasNavigationCube: false,
  },
  modelStyles: {
    isModelXrayed: true,
  },
  markerStyles: {
    selectedMarkerShapeColor: markerThemes.selected.shape,
    selectedMarkerDigitColor: markerThemes.selected.digit,
    selectedMarkerScale: markerThemes.selected.scale,
    unselectedMarkerShapeColor: markerThemes.highlighted.shape,
    unselectedMarkerDigitColor: markerThemes.highlighted.digit,
    unselectedMarkerScale: markerThemes.highlighted.scale,
  },
};

// We need to cast the defaultStylesheet to any because the type of the defaultStylesheet
// is not compatible with the type of the stylesheetConfig because of the `number` types.
export const stylesheetConfig = defaultStylesheet as any as Stylesheet;

export const propertyPaneStyleConfig = [
  {
    sectionName: "General",
    children: [
      {
        propertyName: "generalStyles.hasToolbar",
        label: "Toolbar",
        helpText: "Determines whether the toolbar will be shown or not.",
        controlType: "SWITCH",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.BOOLEAN },
      },
      {
        propertyName: "generalStyles.hasNavigationCube",
        label: "Navigation cube",
        helpText: "Defines whether the navigation cube should be displayed.",
        controlType: "SWITCH",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.BOOLEAN },
      },
    ],
  },
  {
    sectionName: "Model",
    children: [
      {
        propertyName: "modelStyles.isModelXrayed",
        label: "X-rayed model appearance",
        helpText: "Sets the mode of the model appearance",
        controlType: "SWITCH",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.BOOLEAN },
      },
    ],
  },
  {
    sectionName: "Markers",
    children: [
      {
        propertyName: "markerStyles.selectedMarkerShapeColor",
        helpText: "Changes the color of selected marker shape",
        label: "Selected marker shape color",
        controlType: "COLOR_PICKER",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
      {
        propertyName: "markerStyles.selectedMarkerDigitColor",
        helpText: "Changes the color of selected marker digit",
        label: "Selected marker digit color",
        controlType: "COLOR_PICKER",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
      {
        propertyName: "markerStyles.selectedMarkerScale",
        helpText: "Changes the scale of selected marker",
        label: "Selected marker scale",
        controlType: "INPUT_TEXT",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.NUMBER },
      },
      {
        propertyName: "markerStyles.unselectedMarkerShapeColor",
        helpText: "Changes the color of unselected marker shape",
        label: "Unselected marker shape color",
        controlType: "COLOR_PICKER",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
      {
        propertyName: "markerStyles.unselectedMarkerDigitColor",
        helpText: "Changes the color of unselected marker digit",
        label: "Unselected marker digit color",
        controlType: "COLOR_PICKER",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
      {
        propertyName: "markerStyles.unselectedMarkerScale",
        helpText: "Changes the scale of unselected marker",
        label: "Unselected marker scale",
        controlType: "INPUT_TEXT",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.NUMBER },
      },
    ],
  },
];
