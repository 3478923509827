import React from "react";
import equal from "fast-deep-equal/es6";
import type { WidgetProps, WidgetState } from "widgets/BaseWidget";
import BaseWidget from "widgets/BaseWidget";
import type { Edge, Node, NodeId } from "../component";
import { GraphComponent } from "../component";
import { ValidationTypes } from "../../../constants/WidgetValidation";
import type { Stylesheet } from "../../../entities/AppTheming";
import { getAppsmithConfigs } from "@appsmith/configs";
import { Validations } from "utils/validation/common";
import {
  GraphWidgetHierarchicalModeDirection,
  GraphWidgetHierarchicalModeLayering,
  GraphWidgetMode,
} from "./types";
import { getAlignValue } from "./helpers";

export interface GraphWidgetProps extends WidgetProps {
  nodes: Node[];
  edges: Edge[];
  nodeFigure: "Circle" | "RoundedRectangle";

  nodeTextColor: string;
  nodeColor: string;
  edgeColor: string;
  backgroundColor: string;
  nodeBorderColor: string;
  selectedNodeBorderColor: string;

  nodeDiameter: number;
  nodeTextSize: number;
  nodeBorderSize: number;
  selectedNodeBorderSize: number;

  defaultSelectedNodes: NodeId[];

  mode: GraphWidgetMode;
  hierarchicalModeDirection: GraphWidgetHierarchicalModeDirection;
  hierarchicalModeLayering: GraphWidgetHierarchicalModeLayering;
  hierarchicalModeLayerSpacing: string;
  hierarchicalModeColumnSpacing: string;
  hierarchicalModeAlignUpperLeft: boolean;
  hierarchicalModeAlignUpperRight: boolean;
  hierarchicalModeAlignLowerLeft: boolean;
  hierarchicalModeAlignLowerRight: boolean;
}

class GraphWidget extends BaseWidget<GraphWidgetProps, WidgetState> {
  constructor(props: GraphWidgetProps) {
    super(props);
  }

  // Internal state to keep track of selected nodes
  selectedNodeIds: Set<NodeId> = new Set();

  componentDidMount(): void {
    this.updateSelectedNodes();
  }

  componentDidUpdate(prevProps: GraphWidgetProps) {
    const { defaultSelectedNodes } = this.props;

    if (!equal(defaultSelectedNodes, prevProps.defaultSelectedNodes)) {
      this.updateSelectedNodes();
    }
  }

  updateSelectedNodes = () => {
    const defaultSelectedNodes = this.props.defaultSelectedNodes || [];
    this.selectedNodeIds = new Set(defaultSelectedNodes);
    this.updateSelectedNodesInMeta();
  };

  updateSelectedNodesInMeta = () => {
    this.props.updateWidgetMetaProperty(
      "selectedNodes",
      [...this.selectedNodeIds]
        .map((nodeId) => this.props.nodes.find((node) => node.id == nodeId))
        .filter((node) => node != null),
    );
  };

  static getDerivedPropertiesMap() {
    return {};
  }

  static getStylesheetConfig(): Stylesheet {
    return {
      nodeColor: "{{appsmith.theme.colors.primaryColor}}",
      nodeTextColor: "{{appsmith.theme.colors.backgroundColor}}",
      backgroundColor: "{{appsmith.theme.colors.backgroundColor}}",
    };
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "General",
        children: [
          {
            helpText: "Defines edges of the graph.",
            propertyName: "edges",
            label: "Edges",
            controlType: "INPUT_TEXT",
            placeholderText:
              '[{ "id": "1", "from": "1", "to": "2", "label": "has" }]',
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.ARRAY,
              params: {
                unique: ["id"],
                children: {
                  type: ValidationTypes.OBJECT,
                  params: {
                    required: true,
                    allowedKeys: [
                      {
                        name: "id",
                        type: ValidationTypes.TEXT || ValidationTypes.NUMBER,
                        params: {
                          default: "1",
                          requiredKey: true,
                        },
                      },
                      {
                        name: "from",
                        type: ValidationTypes.TEXT || ValidationTypes.NUMBER,
                        params: {
                          default: "",
                          requiredKey: true,
                        },
                      },
                      {
                        name: "to",
                        type: ValidationTypes.TEXT || ValidationTypes.NUMBER,
                        params: {
                          default: "",
                          requiredKey: true,
                        },
                      },
                      {
                        name: "label",
                        type: ValidationTypes.TEXT,
                        params: {
                          default: "has",
                        },
                      },
                    ],
                  },
                },
              },
            },
          },
          {
            helpText: "Defines nodes of the graph.",
            propertyName: "nodes",
            label: "Nodes",
            controlType: "INPUT_TEXT",
            placeholderText: '[{ "id": "1", "label": "wheel" }]',
            inputType: "ARRAY",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.ARRAY,
              params: {
                unique: ["id"],
                children: {
                  type: ValidationTypes.OBJECT,
                  params: {
                    required: true,
                    allowedKeys: [
                      {
                        name: "id",
                        type: ValidationTypes.TEXT || ValidationTypes.NUMBER,
                        params: {
                          default: "1",
                          requiredKey: true,
                        },
                      },
                      {
                        name: "label",
                        type: ValidationTypes.TEXT,
                        params: {
                          default: "first",
                          required: true,
                        },
                      },
                      {
                        name: "color",
                        type: ValidationTypes.TEXT,
                        params: {
                          default: "#553DE9",
                        },
                      },
                    ],
                  },
                },
              },
            },
          },
          {
            helpText: "Defines selected nodes indices of the graph.",
            propertyName: "defaultSelectedNodes",
            label: "Selected nodes",
            controlType: "INPUT_TEXT",
            placeholderText: '["1"]',
            inputType: "ARRAY",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.ARRAY,
              params: {
                default: [],
                children: {
                  type: ValidationTypes.TEXT || ValidationTypes.NUMBER,
                },
              },
            },
          },
        ],
      },
    ];
  }

  static getPropertyPaneStyleConfig() {
    return [
      {
        sectionName: "General",
        children: [
          {
            propertyName: "nodeFigure",
            label: "Node figure",
            helpText: "Node geometrical figure",
            controlType: "DROP_DOWN",
            defaultValue: "circle",
            options: [
              {
                label: "Circle",
                value: "Circle",
              },
              {
                label: "Rounded rectangle",
                value: "RoundedRectangle",
              },
            ],
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "hasToolbar",
            label: "Toolbar",
            helpText: "Toolbar helping in interactions with diagram",
            controlType: "SWITCH",
            defaultValue: false,
            isBindProperty: false,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
        ],
      },
      {
        sectionName: "Mode related properties",
        children: [
          {
            helpText: "Sets the mode of the widget",
            propertyName: "mode",
            label: "Mode",
            controlType: "LABEL_ALIGNMENT_OPTIONS",
            options: [
              { label: "Force directed", value: GraphWidgetMode.ForceDirected },
              { label: "Hierarchical", value: GraphWidgetMode.Hierarchical },
            ],
            isBindProperty: false,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            helpText: "Sets the direction of the graph",
            propertyName: "hierarchicalModeDirection",
            label: "Direction",
            controlType: "LABEL_ALIGNMENT_OPTIONS",
            options: [
              {
                label: "Right",
                value: GraphWidgetHierarchicalModeDirection.Right,
              },
              {
                label: "Down",
                value: GraphWidgetHierarchicalModeDirection.Down,
              },
              {
                label: "Left",
                value: GraphWidgetHierarchicalModeDirection.Left,
              },
              { label: "Up", value: GraphWidgetHierarchicalModeDirection.Up },
            ],
            isBindProperty: false,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
            hidden: (props: GraphWidgetProps) =>
              props.mode !== GraphWidgetMode.Hierarchical,
            dependencies: ["mode"],
          },
          {
            helpText: "Sets the layering of the graph",
            propertyName: "hierarchicalModeLayering",
            label: "Layering",
            controlType: "DROP_DOWN",
            options: [
              {
                label: "Optimal Link Length",
                value: GraphWidgetHierarchicalModeLayering.OptimalLinkLength,
              },
              {
                label: "Longest Path Source",
                value: GraphWidgetHierarchicalModeLayering.LongestPathSource,
              },
              {
                label: "Longest Path Sink",
                value: GraphWidgetHierarchicalModeLayering.LongestPathSink,
              },
            ],
            isBindProperty: false,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
            hidden: (props: GraphWidgetProps) =>
              props.mode !== GraphWidgetMode.Hierarchical,
            dependencies: ["mode"],
          },
          {
            helpText: "Sets the spacing between layers",
            propertyName: "hierarchicalModeLayerSpacing",
            label: "Layer Spacing",
            placeholderText: "Enter integer value",
            controlType: "NUMERIC_INPUT",
            isBindProperty: false,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.NUMBER },
            hidden: (props: GraphWidgetProps) =>
              props.mode !== GraphWidgetMode.Hierarchical,
            dependencies: ["mode"],
          },
          {
            helpText: "Sets the spacing between columns",
            propertyName: "hierarchicalModeColumnSpacing",
            label: "Column Spacing",
            placeholderText: "Enter integer value",
            controlType: "NUMERIC_INPUT",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.NUMBER,
            },
            hidden: (props: GraphWidgetProps) =>
              props.mode !== GraphWidgetMode.Hierarchical,
            dependencies: ["mode"],
          },
          {
            sectionName: "Align",
            hidden: (props: GraphWidgetProps) =>
              props.mode !== GraphWidgetMode.Hierarchical,
            dependencies: ["mode"],
            children: [
              {
                propertyName: "hierarchicalModeAlignUpperLeft",
                label: "Upper Left",
                helpText: "Hierarchical upper left alignment",
                controlType: "SWITCH",
                isBindProperty: false,
                isTriggerProperty: false,
                validation: { type: ValidationTypes.BOOLEAN },
              },
              {
                propertyName: "hierarchicalModeAlignUpperRight",
                label: "Upper Right",
                helpText: "Hierarchical upper right alignment",
                controlType: "SWITCH",
                isBindProperty: false,
                isTriggerProperty: false,
                validation: { type: ValidationTypes.BOOLEAN },
              },
              {
                propertyName: "hierarchicalModeAlignLowerLeft",
                label: "Lower Left",
                helpText: "Hierarchical lower left alignment",
                controlType: "SWITCH",
                isBindProperty: false,
                isTriggerProperty: false,
                validation: { type: ValidationTypes.BOOLEAN },
              },
              {
                propertyName: "hierarchicalModeAlignLowerRight",
                label: "Lower Right",
                helpText: "Hierarchical lower right alignment",
                controlType: "SWITCH",
                isBindProperty: false,
                isTriggerProperty: false,
                validation: { type: ValidationTypes.BOOLEAN },
              },
            ],
          },
        ],
      },
      {
        sectionName: "Size",
        children: [
          {
            helpText: "Node diameter",
            propertyName: "nodeDiameter",
            label: "Node diameter",
            placeholderText: "Enter integer value",
            controlType: "NUMERIC_INPUT",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.NUMBER,
            },
          },
          {
            helpText: "Node text size",
            propertyName: "nodeTextSize",
            label: "Node text size in pt",
            placeholderText: "Enter integer value",
            controlType: "NUMERIC_INPUT",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.NUMBER,
            },
          },
          {
            helpText: "Node border size",
            propertyName: "nodeBorderSize",
            label: "Node border size",
            placeholderText: "Enter integer value",
            controlType: "NUMERIC_INPUT",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.NUMBER,
            },
          },
          {
            helpText: "Selected node border size",
            propertyName: "selectedNodeBorderSize",
            label: "Selected node border size",
            placeholderText: "Enter integer value",
            controlType: "NUMERIC_INPUT",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.NUMBER,
            },
          },
        ],
      },
      {
        sectionName: "Color",
        children: [
          {
            propertyName: "backgroundColor",
            label: "Background color",
            helpText: "Background color",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: Validations.COLOR,
          },
          {
            propertyName: "nodeTextColor",
            label: "Node Text Color",
            helpText: "Controls the color of the text displayed inside nodes",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: Validations.COLOR,
          },
          {
            propertyName: "nodeColor",
            label: "Node Color",
            helpText: "Background node color",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: Validations.COLOR,
          },
          {
            propertyName: "edgeColor",
            label: "Edge Color",
            helpText: "Edge color",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: Validations.COLOR,
          },
          {
            propertyName: "selectedNodeBorderColor",
            label: "Selected node border color",
            helpText: "Selected node border color",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: Validations.COLOR,
          },
          {
            propertyName: "nodeBorderColor",
            label: "Node border color",
            helpText: "Node border color",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: Validations.COLOR,
          },
        ],
      },
    ];
  }

  onAddNodeToSelection = (nodeId: NodeId) => {
    this.selectedNodeIds.add(nodeId);
    this.updateSelectedNodesInMeta();
  };

  onRemoveNodeFromSelection = (nodeId: NodeId) => {
    this.selectedNodeIds.delete(nodeId);
    this.updateSelectedNodesInMeta();
  };

  onClearSelection = () => {
    this.selectedNodeIds.clear();
    this.updateSelectedNodesInMeta();
  };

  getPageView() {
    const {
      hierarchicalModeAlignLowerLeft,
      hierarchicalModeAlignLowerRight,
      hierarchicalModeAlignUpperLeft,
      hierarchicalModeAlignUpperRight,
      hierarchicalModeColumnSpacing,
      hierarchicalModeDirection,
      hierarchicalModeLayering,
      hierarchicalModeLayerSpacing,
      mode,
    } = this.props;

    const modeRelatedProperties = {
      mode,
      hierarchicalModeAlignOption: getAlignValue(
        hierarchicalModeAlignLowerLeft,
        hierarchicalModeAlignLowerRight,
        hierarchicalModeAlignUpperLeft,
        hierarchicalModeAlignUpperRight,
      ),
      hierarchicalModeColumnSpacing: parseInt(
        hierarchicalModeColumnSpacing,
        10,
      ),
      hierarchicalModeDirection,
      hierarchicalModeLayering,
      hierarchicalModeLayerSpacing: parseInt(hierarchicalModeLayerSpacing, 10),
    };

    return (
      <GraphComponent
        backgroundColor={this.props.backgroundColor}
        edgeColor={this.props.edgeColor}
        gojsLicenseKey={getAppsmithConfigs().gojsLicenseKey}
        graph={{
          nodes: this.props.nodes || [],
          edges: this.props.edges || [],
          selectedNodeIds: [...this.selectedNodeIds],
        }}
        hasToolbar={this.props.hasToolbar}
        nodeBorderColor={this.props.nodeBorderColor}
        nodeBorderSize={this.props.nodeBorderSize}
        nodeColor={this.props.nodeColor}
        nodeDiameter={this.props.nodeDiameter}
        nodeFigure={this.props.nodeFigure}
        nodeTextColor={this.props.nodeTextColor}
        nodeTextSize={this.props.nodeTextSize}
        onAddNodeToSelection={this.onAddNodeToSelection}
        onClearSelection={this.onClearSelection}
        onRemoveNodeFromSelection={this.onRemoveNodeFromSelection}
        selectedNodeBorderColor={this.props.selectedNodeBorderColor}
        selectedNodeBorderSize={this.props.selectedNodeBorderSize}
        {...modeRelatedProperties}
      />
    );
  }

  static getWidgetType(): string {
    return "GRAPH_WIDGET";
  }
}

export default GraphWidget;
