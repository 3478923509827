import React from "react";

import * as go from "gojs";
import type { ThemeColor } from "./theme";
import { theme } from "./theme";

export type OverviewProps = {
  overviewId: string;
  backgroundColor?: ThemeColor;
  hasMiniMap: boolean;
};

// eslint-disable-next-line @typescript-eslint/unbound-method
const $ = go.GraphObject.make;

export const makeInitOverview = (
  diagram: go.Diagram,
  strokeColor: string,
  overviewId: string,
) => {
  const overview = $(go.Overview, overviewId, {
    observed: diagram,
    contentAlignment: go.Spot.Center,
  });
  const box = overview.box.elt(0) as go.Shape;

  box.stroke = strokeColor;
};

export const Overview: React.FC<OverviewProps> = React.memo(
  ({
    hasMiniMap,
    backgroundColor = theme.colors.tertiary.whiteDarken,
    overviewId,
  }) => {
    return (
      <div
        id={overviewId}
        style={{
          display: hasMiniMap ? "block" : "none",
          backgroundColor: backgroundColor,
          borderRadius: 6,
          bottom: 39,
          boxShadow: theme.shadow.alert,
          height: 135,
          position: "absolute",
          right: 39,
          width: 240,
          zIndex: theme.zIdx.diagramOverview,
        }}
      />
    );
  },
);

Overview.displayName = "Overview";
