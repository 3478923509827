export enum GraphWidgetMode {
  ForceDirected = "FORCE_DIRECTED",
  Hierarchical = "HIERARCHICAL",
}

export enum GraphWidgetHierarchicalModeDirection {
  Right = "RIGHT",
  Down = "DOWN",
  Left = "LEFT",
  Up = "UP",
}

export enum GraphWidgetHierarchicalModeLayering {
  OptimalLinkLength = "OPTIMAL_LINK_LENGTH",
  LongestPathSource = "LONGEST_PATH_SOURCE",
  LongestPathSink = "LONGEST_PATH_SINK",
}
