import Widget from "./widget";

import IconSVG from "./icon.svg";

const defaultProps = {
  connections: [
    {
      colors: [
        {
          hexCode: "#A52A2A",
          name: "Braun",
        },
      ],
      crossSectionArea: 4,
      id: "143007",
      origin: "Modul",
      partNumber: "8Y1_974_143",
      segmentFiles: [
        "file:///file-server-data/shared/data/wire-segments/5ee562e0-420d-4f7e-ac37-db5f77999bb8/Segment.obj",
      ],
      signalName: "M.31",
      wireLength: 702.09,
      wireType: "individual wire",
    },
    {
      colors: [],
      crossSectionArea: 0,
      id: "174c2373-2990-45ac-b6bb-1455a08dea94",
      origin: "",
      partNumber: "",
      segmentFiles: [],
      signalName: "M.31",
      wireLength: 0,
      wireType: "internal",
    },
    {
      colors: [
        {
          hexCode: "#A52A2A",
          name: "Braun",
        },
      ],
      crossSectionArea: 0.35,
      id: "143017",
      origin: "Modul",
      partNumber: "8Y1_974_143",
      segmentFiles: [
        "file:///file-server-data/shared/data/wire-segments/76b27180-02c8-4e7d-b191-3429d34a41af/Segment.obj",
      ],
      signalName: "M.31",
      wireLength: 588.77,
      wireType: "individual wire",
    },
  ],
  paths: [
    {
      path: [
        "11*1@A1",
        "143007",
        "NX6*7@A9",
        "174c2373-2990-45ac-b6bb-1455a08dea94",
        "NX6*7@C1",
        "143017",
        "86*1@1",
      ],
    },
  ],
  terminals: [
    {
      componentReferenceId: "11",
      componentType: "GroundPoint",
      connectionSignalName: "M.31",
      connectorColor: null,
      connectorName: "1",
      connectorNameAdditional: "MB7_A1_LL",
      connectorPartNumber: "8K0_971_859_M",
      contactSurface: "sn",
      coordinates: [
        {
          coordinate: [0.022623482, -0.36798117, 0.5339945],
        },
      ],
      description: "Massepunkt im Batteriekasten [MB7]",
      id: "11*1@A1",
      partNumber: "8K0_971_859_M",
      terminalName: "A1",
    },
    {
      componentReferenceId: "NX6",
      componentType: "Other",
      connectionSignalName: "M.31",
      connectorColor: {
        hexCode: "#000000",
        name: "Schwarz",
      },
      connectorName: "7",
      connectorPartNumber: "1S0_973_026_A",
      contactSurface: "sn",
      coordinates: [
        {
          coordinate: [0.00475274, -0.19127359000000002, 0.38409344],
        },
      ],
      description: "Bremskraftverstärker",
      id: "NX6*7@A9",
      partNumber: "N_105_119_02",
      terminalName: "A9",
    },
    {
      componentReferenceId: "NX6",
      componentType: "Other",
      connectionSignalName: "M.31",
      connectorColor: {
        hexCode: "#000000",
        name: "Schwarz",
      },
      connectorName: "7",
      connectorPartNumber: "8K0_973_702",
      contactSurface: "sn",
      coordinates: [
        {
          coordinate: [0.00475274, -0.19127359000000002, 0.38409344],
        },
      ],
      description: "Bremskraftverstärker",
      id: "NX6*7@C1",
      partNumber: "N_105_111_03",
      terminalName: "C1",
    },
    {
      componentReferenceId: "86",
      componentType: "Splice",
      connectionSignalName: "M.31",
      connectorColor: null,
      connectorName: "1",
      connectorNameAdditional: "SP_MB3_A11",
      connectorPartNumber: "SPLICE",
      contactSurface: "",
      coordinates: [
        {
          coordinate: [-0.06929952, -0.39842413, 0.43523267],
        },
      ],
      description: "Masseverbindung 1 im Leitungsstrang hinten",
      id: "86*1@1",
      partNumber: "",
      terminalName: "1",
    },
  ],
};

const defaultStyleProps = {
  hasMiniMap: true,
  scale: "0.5",
  hasToolbar: false,
};

export const CONFIG = {
  type: Widget.getWidgetType(),
  name: "Diagram", // The display name which will be made in uppercase and show in the widgets panel ( can have spaces )
  iconSVG: IconSVG,
  needsMeta: true, // Defines if this widget adds any meta properties
  isCanvas: false, // Defines if this widget has a canvas within in which we can drop other widgets
  defaults: {
    ...defaultProps,
    ...defaultStyleProps,
    widgetName: "Diagram",
    rows: 60,
    columns: 60,
    version: 1,
  },
  properties: {
    derived: Widget.getDerivedPropertiesMap(),
    default: Widget.getDefaultPropertiesMap(),
    meta: Widget.getMetaPropertiesMap(),
    config: Widget.getPropertyPaneConfig(),
    contentConfig: Widget.getPropertyPaneContentConfig(),
    styleConfig: Widget.getPropertyPaneStyleConfig(),
    stylesheetConfig: Widget.getStylesheetConfig(),
  },
};

export default Widget;
