import Widget from "./widget";
import IconSVG from "./icon.svg";

const defaultProps = {
  modelSceneGraphUrl: "/widgets-assets/renderer-widget/funamera",
  markers: [
    {
      id: "marker1",
      label: "Unit 1",
      shape: "cube",
      position: { x: -3700, y: -1000, z: 1800 },
      digit: 1,
    },
    {
      id: "marker2",
      label: "Unit 2",
      shape: "sphere",
      position: { x: -3000, y: -2000, z: 1500 },
      digit: 2,
    },
  ],
  defaultSelectedMarkerId: "marker2",
  pills: [
    {
      id: "marker2",
      label: "Unit 2",
      position: { x: -3000, y: -2000, z: 1500 },
    },
  ],
  defaultSelectedNodesIds: [],
};

export const CONFIG = {
  type: Widget.getWidgetType(),
  name: "Renderer", // The display name which will be made in uppercase and show in the widgets panel ( can have spaces )
  iconSVG: IconSVG,
  needsMeta: true, // Defines if this widget adds any meta properties
  isCanvas: false, // Defines if this widget has a canvas within in which we can drop other widgets
  defaults: {
    ...defaultProps,
    widgetName: "Renderer",
    rows: 40,
    columns: 40,
    version: 1,
  },
  properties: {
    derived: Widget.getDerivedPropertiesMap(),
    default: Widget.getDefaultPropertiesMap(),
    meta: Widget.getMetaPropertiesMap(),
    config: Widget.getPropertyPaneConfig(),
    contentConfig: Widget.getPropertyPaneContentConfig(),
    styleConfig: Widget.getPropertyPaneStyleConfig(),
    stylesheetConfig: Widget.getStylesheetConfig(),
    autocompleteDefinitions: Widget.getAutocompleteDefinitions(),
  },
};

export default Widget;
