import type { NodeRendererProps } from "react-arborist";
import React from "react";
import { ArrowRight } from "../arrowRight";
import styled from "styled-components";

export type TreeNode = {
  id: string;
  label: string;
  children?: TreeNode[];
};

const Label = styled.div`
  font-weight: 400;
  font-size: 14px;
  font-family: "Open Sans";
`;

const Row = styled.div<{ isSelected: boolean }>`
  background: ${({ isSelected }) =>
    isSelected ? "rgba(241, 243, 250, 1)" : "white"};
  display: flex;
  align-items: center;
  height: 100%;
`;

const Space = styled.div`
  width: 32px;
  height: 100%;
`;

const ArrowIconContainer = styled.div`
  width: 32px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ArrowIconWrapper = styled.div<{ isOpen: boolean }>`
  transform: rotate(
    ${({ isOpen }) => {
      return isOpen ? "90" : "0";
    }}deg
  );

  transition: transform ease 0.3s;
`;
export const Node: React.FC<NodeRendererProps<TreeNode>> = ({
  node,
  style,
  tree,
}) => {
  const isSelected = tree.selectedIds.has(node.id);

  return (
    <Row isSelected={isSelected} style={style}>
      {node.isLeaf ? (
        <Space />
      ) : (
        <ArrowIconContainer onClick={() => node.toggle()}>
          <ArrowIconWrapper isOpen={node.isOpen}>
            <ArrowRight />
          </ArrowIconWrapper>
        </ArrowIconContainer>
      )}
      <Label>{node.data.label}</Label>
    </Row>
  );
};

Node.displayName = "Node";
