import React, { useCallback, useEffect, useMemo, useState } from "react";

import type { ViewerContext3D } from "@spread-ai/softy-renderer-react";
import {
  NavigationCube,
  Viewer,
  createViewerContext3D,
} from "@spread-ai/softy-renderer-react";
import { isNil } from "lodash";
import styled from "styled-components";

import type { Marker, Pill } from "../widget/types";
import type {
  GeneralStyles,
  MarkersStyles,
  ModelStyles,
} from "../widget/styleConfig";

import { ViewerApi } from "./ViewerApi";

import { useMarkers } from "./markers/useMarkers";
import { buildMarkersList } from "./markers/helpers";

import { PillsOverlay } from "./pills/PillsOverlay";
import { usePills } from "./pills/usePills";
import { buildPillsList } from "./pills/helpers";

import Toolbar from "./Toolbar";

interface RendererComponentProps {
  // Default
  widgetId: string;
  // General
  generalStyles: GeneralStyles;
  // Model
  modelSceneGraphUrl: string;
  modelStyles: ModelStyles;
  // Node selection
  onNodeSelectionChange: (selectedNodesIds: string[]) => void;
  defaultSelectedNodesIds: string[];
  // Markers
  markers: Marker[];
  markersStyle: MarkersStyles;
  defaultSelectedMarkerId: string;
  onMarkerSelected: (markerId: string) => void;
  // Pills
  pills: Pill[];
}

const RendererComponent = (props: RendererComponentProps) => {
  const [viewerContext, setViewerContext] = useState<ViewerContext3D>();
  const [isModelAdded, setIsModelAdded] = useState(false);

  // Initialization of viewer context
  useEffect(() => {
    let _viewerContext: ViewerContext3D;

    createViewerContext3D({
      models: [],
      isHighQuality: false,
      timeouts: {
        // default
      },
    }).then((newViewerContext) => {
      _viewerContext = newViewerContext;
      setViewerContext(newViewerContext);
    });

    return () => {
      _viewerContext.destroy();
    };
  }, [props.widgetId]);

  // Initialization of ViewerApi
  const viewerApi = useMemo(() => {
    if (viewerContext) {
      return new ViewerApi(viewerContext);
    }
  }, [viewerContext]);

  // Handling X-rayed appearance option
  useEffect(() => {
    if (!viewerContext || !isModelAdded) return;

    const id = viewerContext.getInitialModelId();
    if (isNil(id)) return;

    viewerContext.setNodeXray(id, props.modelStyles.isModelXrayed);
    viewerContext.setNodeClickable(id, !props.modelStyles.isModelXrayed);
  }, [viewerContext, isModelAdded, props.modelStyles.isModelXrayed]);

  // Model rendering
  useEffect(() => {
    if (!viewerContext) return;

    if (props.modelSceneGraphUrl) {
      setIsModelAdded(false);
      viewerContext.drawSceneGraph(props.modelSceneGraphUrl).then(() => {
        setIsModelAdded(true);
      });
    } else {
      viewerContext.reset();
    }
  }, [viewerContext, props.modelSceneGraphUrl]);

  // Markers
  const [selectedMarkerId, setSelectedMarkerId] = useState(
    props.defaultSelectedMarkerId,
  );

  const markers = useMemo(
    () =>
      buildMarkersList({
        markers: props.markers,
        selectedMarkerId,
        style: props.markersStyle,
      }),
    [
      props.markers,
      selectedMarkerId,
      props.markersStyle,
      // We need to re-render when the model changes, because the markers are
      // relative to the model and will be cleared when the model is changed.
      props.modelSceneGraphUrl,
    ],
  );

  const { selectedMarker } = useMarkers(viewerApi, isModelAdded, markers, [
    viewerContext,
    props.modelSceneGraphUrl,
  ]);

  // Updating selected marker by click on it
  useEffect(() => {
    if (selectedMarker) {
      setSelectedMarkerId(selectedMarker.id);
    }
  }, [selectedMarker]);

  // Updating selected marker by API
  useEffect(() => {
    setSelectedMarkerId(props.defaultSelectedMarkerId.trim());
  }, [props.defaultSelectedMarkerId]);

  // Pills
  const pillsList = useMemo(() => buildPillsList(props.pills), [props.pills]);
  const pills = usePills(viewerApi, isModelAdded, pillsList, [
    viewerContext,
    props.modelSceneGraphUrl,
  ]);

  // Node selection listener (outcoming)
  useEffect(() => {
    if (!viewerApi || !isModelAdded) return;

    // Subscription
    const unsubscribe = viewerApi.addEventListener(
      "selectionchange",
      (selection) => {
        const selectedNodesIds = selection.map((item) => item.id);
        props.onNodeSelectionChange(selectedNodesIds);
      },
    );

    // Unsubscription
    return () => unsubscribe();
  }, [viewerApi, isModelAdded]);

  // Node selection update (incoming)
  useEffect(() => {
    if (!viewerContext || !isModelAdded) return;

    viewerContext.setSelectedNodes(props.defaultSelectedNodesIds);
  }, [viewerContext, isModelAdded, props.defaultSelectedNodesIds]);

  const onFocus = useCallback(() => {
    if (!viewerContext) return;
    viewerContext.focusOnSelection();
  }, [viewerContext]);

  const onReset = useCallback(() => {
    if (!viewerContext) return;
    viewerContext.resetCamera();
  }, [viewerContext]);

  if (!viewerContext) return null;

  const NavigationCubeSection = props.generalStyles.hasNavigationCube && (
    <NavCubeContainer>
      <NavigationCube viewerContext={viewerContext} />
    </NavCubeContainer>
  );

  const PillsSection = !isNil(pills) && <PillsOverlay pillData={pills} />;

  const ToolbarSection = props.generalStyles.hasToolbar && (
    <Toolbar onFocus={onFocus} onReset={onReset} />
  );

  return (
    <Container>
      <Viewer viewerContext={viewerContext} />
      {NavigationCubeSection}
      {PillsSection}
      {ToolbarSection}
    </Container>
  );
};

export default RendererComponent;

const Container = styled.div`
  display: flex;
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
  border-width: 1px;
`;

const NavCubeContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
`;
