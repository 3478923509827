import Widget from "./widget";
import {
  GraphWidgetHierarchicalModeDirection,
  GraphWidgetHierarchicalModeLayering,
  GraphWidgetMode,
} from "./widget/types";
import IconSVG from "./icon.svg";

const defaultProps = {
  edges: [
    {
      id: "0",
      from: "0",
      to: "2",
      label: "made of",
    },
    {
      id: "1",
      from: "1",
      to: "2",
      label: "made of",
    },
  ],
  nodes: [
    {
      id: "0",
      label: "rim",
      color: "#89fbac",
    },
    {
      id: "1",
      label: "body",
      color: "#85f5df",
    },
    {
      id: "2",
      label: "steel",
      color: "#b188a3",
    },
  ],
  defaultSelectedNodes: [],
};

const defaultStyleProps = {
  // General
  nodeFigure: "Circle",
  // Mode related props
  mode: GraphWidgetMode.ForceDirected,
  hierarchicalModeDirection: GraphWidgetHierarchicalModeDirection.Right,
  hierarchicalModeLayering:
    GraphWidgetHierarchicalModeLayering.OptimalLinkLength,
  hierarchicalModeLayerSpacing: "75", // should be a string to be parsed as int
  hierarchicalModeColumnSpacing: "75", // should be a string to be parsed as int
  hierarchicalModeAlignUpperLeft: true,
  hierarchicalModeAlignUpperRight: true,
  hierarchicalModeAlignLowerLeft: true,
  hierarchicalModeAlignLowerRight: true,
  // Sizes and colors
  edgeColor: "#000000",
  selectedNodeBorderSize: 2,
  selectedNodeBorderColor: "#4ade80",
  nodeBorderSize: 0,
  nodeBorderColor: "#000000",
  nodeDiameter: 60,
  nodeTextSize: 10,
  hasToolbar: false,
};

export const CONFIG = {
  type: Widget.getWidgetType(),
  name: "Graph", // The display name which will be made in uppercase and show in the widgets panel ( can have spaces )
  iconSVG: IconSVG,
  needsMeta: true, // Defines if this widget adds any meta properties
  isCanvas: false, // Defines if this widget has a canvas within in which we can drop other widgets
  defaults: {
    ...defaultProps,
    ...defaultStyleProps,
    widgetName: "Graph",
    rows: 40,
    columns: 40,
    version: 1,
  },
  properties: {
    derived: Widget.getDerivedPropertiesMap(),
    default: Widget.getDefaultPropertiesMap(),
    meta: Widget.getMetaPropertiesMap(),
    config: Widget.getPropertyPaneConfig(),
    contentConfig: Widget.getPropertyPaneContentConfig(),
    styleConfig: Widget.getPropertyPaneStyleConfig(),
    stylesheetConfig: Widget.getStylesheetConfig(),
  },
};

export default Widget;
