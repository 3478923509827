import React, { useMemo } from "react";

import type { NodeApi } from "react-arborist";
import { Tree as TreeArborist } from "react-arborist";
import { Node } from "./Node";
import type { TreeNode } from "./Node";
import styled from "styled-components";

export interface TreeProps {
  initialData: TreeNode[];
  onSelectionChange: (ids: NodeApi<TreeNode>[]) => void;
  searchTerm: string;
  height: number;
  width: number;
}

const NoSearchResults = styled.div`
  font-weight: 400;
  font-size: 14px;
  font-family: "Open Sans";
`;

function extractChildrenValuesFromArray(treeData: TreeNode[]): TreeNode[] {
  let childrenValues: TreeNode[] = [];

  for (const node of treeData) {
    childrenValues.push(node);

    if (node.children) {
      const children = extractChildrenValuesFromArray(node.children);
      childrenValues = childrenValues.concat(children);
    }
  }

  return childrenValues;
}
export const Tree: React.FC<TreeProps> = ({
  height,
  initialData,
  onSelectionChange,
  searchTerm,
  width,
}) => {
  const isSearchMatch = useMemo(() => {
    if (!searchTerm) {
      return;
    }

    const match = extractChildrenValuesFromArray(initialData).find(
      (element) => {
        if (!element?.label) {
          return;
        }

        return element.label.toLowerCase().includes(searchTerm.toLowerCase());
      },
    );

    return Boolean(match);
  }, [searchTerm, initialData]);

  return (
    <div>
      {!isSearchMatch && searchTerm !== "" ? (
        <NoSearchResults>No search result</NoSearchResults>
      ) : (
        <TreeArborist
          height={height}
          indent={24}
          initialData={initialData}
          onSelect={onSelectionChange}
          rowHeight={32}
          searchTerm={searchTerm}
          width={width}
        >
          {Node}
        </TreeArborist>
      )}
    </div>
  );
};

Tree.displayName = "Tree";
