import type { RecursiveNode } from "@spread-ai/softy-renderer-react";

import type { ViewerApi } from "../ViewerApi";

import type { MarkerShape } from "./types";

const shapeContainersDict: Record<MarkerShape, string> = {
  triangle: "Triangle_asm v3:1",
  cube: "Quader_asm v2:1",
  sphere: "Sphere_asm v7:1",
  diamond: "Diamond_asm v7:1",
  cross: "X_asm v5:1",
};

async function getShapeContainers(viewer: ViewerApi, nodeId: string) {
  const subtree = await viewer.getSubtree(nodeId);

  if (!subtree || !subtree.children?.length || !subtree.children[0].children) {
    // model does not exist in the renderer
    return null;
  }

  const shapeContainers = subtree.children[0].children.reduce((acc, child) => {
    const shapeContainersDictKey = Object.keys(shapeContainersDict).find(
      (key) => shapeContainersDict[key as MarkerShape] === child.name,
    );
    return {
      ...acc,
      ...(shapeContainersDictKey ? { [shapeContainersDictKey]: child } : {}),
    };
  }, {} as Record<MarkerShape, RecursiveNode>);

  return shapeContainers;
}

const shapeContentDict = {
  shape: "_grey",
  digit1: "_1_w",
  digit2: "_2_w",
  digit3: "_3_w",
  digit4: "_4_w",
  digit5: "_5_w",
  digit6: "_6_w",
  digit7: "_7_w",
  digit8: "_8_w",
  digit9: "_9_w",
} as const;

type ShapeContentDictKey = keyof typeof shapeContentDict;

/**
 * Get all the relevant node IDs from the model depending on the marker shape.
 * The marker model has all the shapes in the following order: triangle, cube, sphere, diamond, cross.
 *
 * A rendered marker model has the following structure:
 * - root node (the given `markerNodeId`)
 *   - 1st shape container node (triangle)
 *     - children container node
 *       - shape node
 *       - number 1 node
 *       - number 2 node
 *       - number 3 node (and so on...)
 *   - 2nd shape container node (cube)
 *     - [same structure as above]
 *
 * ... and so on ...
 */
export async function getShapeNodeIdsFromModel(
  viewer: ViewerApi,
  markerNodeId: string,
  shape: MarkerShape,
) {
  try {
    const markerShapes = await getShapeContainers(viewer, markerNodeId);
    if (!markerShapes) {
      return null;
    }

    const targetMarkerShape = markerShapes[shape];
    if (!targetMarkerShape.children) {
      return null;
    }

    const nodeIds = targetMarkerShape.children.reduce((acc, child) => {
      const dictKey = Object.keys(shapeContentDict).find((key) => {
        const substring = shapeContentDict[key as ShapeContentDictKey];
        return child.name.includes(substring);
      });
      return { ...acc, ...(dictKey ? { [dictKey]: child.id } : {}) };
    }, {} as Record<ShapeContentDictKey, string>);

    return { containerNode: targetMarkerShape, nodeIds };
  } catch (error) {
    return null;
  }
}

/**
 * Find a parent node ID by crawling up the node tree by an specific amount of `ancestry`, `1` being the direct parent.
 */
async function findParentNode(
  viewer: ViewerApi,
  nodeId: string,
  ancestry: number,
): Promise<string | null> {
  const parentNodeId = await viewer.getNodeParent(nodeId);
  if (parentNodeId === undefined) {
    return null;
  }

  if (ancestry === 1) {
    return parentNodeId;
  }

  return findParentNode(viewer, parentNodeId as string, ancestry - 1);
}

export async function getRootNodeIdFromShape(
  viewer: ViewerApi,
  shapeNodeId: string,
) {
  try {
    const rootNodeId = await findParentNode(viewer, shapeNodeId, 3);

    return rootNodeId;
  } catch (error) {
    return null;
  }
}
