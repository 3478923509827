import React from "react";
import type { WidgetProps, WidgetState } from "widgets/BaseWidget";
import BaseWidget from "widgets/BaseWidget";
import RendererComponent from "../component";
import { ValidationTypes } from "../../../constants/WidgetValidation";
import type { Stylesheet } from "entities/AppTheming";
import type { Marker, Pill } from "./types";
import type { StyleProps } from "./styleConfig";
import { propertyPaneStyleConfig, stylesheetConfig } from "./styleConfig";
import { DefaultAutocompleteDefinitions } from "widgets/WidgetUtils";
import type { AutocompletionDefinitions } from "widgets/constants";
import { generateTypeDef } from "utils/autocomplete/dataTreeTypeDefCreator";

export interface RendererWidgetProps extends WidgetProps, StyleProps {
  // Model
  modelSceneGraphUrl: string;

  // Markers
  markers: Marker[];
  defaultSelectedMarkerId: string;

  // Pills
  pills: Pill[];

  // Node selection
  defaultSelectedNodesIds: string[];
}

class RendererWidget extends BaseWidget<RendererWidgetProps, WidgetState> {
  updateSelectedMarkerId = (selectedMarkerId: string) => {
    this.props.updateWidgetMetaProperty("selectedMarkerId", selectedMarkerId);
  };

  updateSelectedNodesIds = (selectedNodesIds: string[]) => {
    this.props.updateWidgetMetaProperty("selectedNodesIds", selectedNodesIds);
  };

  componentDidMount(): void {
    const { defaultSelectedMarkerId, defaultSelectedNodesIds } = this.props;
    this.updateSelectedMarkerId(defaultSelectedMarkerId);
    this.updateSelectedNodesIds(defaultSelectedNodesIds);
  }

  componentDidUpdate(prevProps: RendererWidgetProps): void {
    const { defaultSelectedMarkerId, defaultSelectedNodesIds } = this.props;

    if (prevProps.defaultSelectedMarkerId !== defaultSelectedMarkerId) {
      this.updateSelectedMarkerId(defaultSelectedMarkerId);
    }

    if (prevProps.defaultSelectedNodesIds !== defaultSelectedNodesIds) {
      this.updateSelectedNodesIds(defaultSelectedNodesIds);
    }
  }

  static getDerivedPropertiesMap() {
    return {};
  }

  static getAutocompleteDefinitions(): AutocompletionDefinitions {
    return (widget: RendererWidgetProps) => ({
      "!doc": "Renderer widget is used to display 3D models.",
      isVisible: DefaultAutocompleteDefinitions.isVisible,
      modelSceneGraphUrl: generateTypeDef(widget.modelSceneGraphUrl),
      markers: generateTypeDef(widget.markers),
      selectedMarkerId: generateTypeDef(widget.defaultSelectedMarkerId),
      pills: generateTypeDef(widget.pills),
      selectedNodesIds: generateTypeDef(widget.defaultSelectedNodesIds),
    });
  }

  static getStylesheetConfig(): Stylesheet {
    return stylesheetConfig;
  }

  static getPropertyPaneStyleConfig() {
    return propertyPaneStyleConfig;
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "General",
        children: [
          {
            propertyName: "modelSceneGraphUrl",
            label: "modelSceneGraphUrl",
            helperText: "Defines path to model scene graph directory.",
            controlType: "INPUT_TEXT",
            placeholderText: "/widgets-assets/renderer-widget/demo_model",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
            },
          },
          {
            propertyName: "defaultSelectedNodesIds",
            label: "Selected nodes IDs",
            helperText: "Defines default selected nodes.",
            controlType: "INPUT_TEXT",
            placeholderText: '["6c947224-b7ae-4f39-978f-ebd351b1946e"]',
            inputType: "ARRAY",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.ARRAY,
              params: {
                children: {
                  type: ValidationTypes.TEXT,
                },
              },
            },
          },
          {
            propertyName: "markers",
            label: "Markers",
            helperText: "Defines a list of markers.",
            controlType: "INPUT_TEXT",
            placeholderText:
              '[{"id": "marker1", "shape": "cube", "position": {"x": 0, "y": 0, "z": 0}, "digit": 1}]',
            inputType: "ARRAY",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.ARRAY,
              params: {
                children: {
                  type: ValidationTypes.OBJECT,
                  params: {
                    allowedKeys: [
                      {
                        name: "id",
                        type: ValidationTypes.TEXT,
                        params: {
                          unique: true,
                          required: true,
                        },
                      },
                      {
                        name: "label",
                        type: ValidationTypes.TEXT,
                        params: {
                          required: true,
                        },
                      },
                      {
                        name: "shape",
                        type: ValidationTypes.TEXT,
                        params: {
                          regex: /^(sphere|cube|triangle|diamond|cross)$/,
                          required: true,
                        },
                      },
                      {
                        name: "position",
                        type: ValidationTypes.OBJECT,
                        params: {
                          required: true,
                          allowedKeys: [
                            {
                              name: "x",
                              type: ValidationTypes.NUMBER,
                              params: {
                                required: true,
                              },
                            },
                            {
                              name: "y",
                              type: ValidationTypes.NUMBER,
                              params: {
                                required: true,
                              },
                            },
                            {
                              name: "z",
                              type: ValidationTypes.NUMBER,
                              params: {
                                required: true,
                              },
                            },
                          ],
                        },
                      },
                      {
                        name: "digit",
                        type: ValidationTypes.NUMBER,
                      },
                    ],
                  },
                },
              },
            },
          },
          {
            propertyName: "defaultSelectedMarkerId",
            label: "Selected marker ID",
            helperText: "Defines selected marker ID.",
            controlType: "INPUT_TEXT",
            placeholderText: "marker1",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
            },
          },
          {
            propertyName: "pills",
            label: "Pills",
            helperText: "Defines a list of pills.",
            controlType: "INPUT_TEXT",
            placeholderText:
              '[{"id": "pill1", "label": "Pill 1", "position": {"x": 0, "y": 0, "z": 0}}]',
            inputType: "ARRAY",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.ARRAY,
              params: {
                children: {
                  type: ValidationTypes.OBJECT,
                  params: {
                    allowedKeys: [
                      {
                        name: "id",
                        type: ValidationTypes.TEXT,
                        params: {
                          unique: true,
                          required: true,
                        },
                      },
                      {
                        name: "label",
                        type: ValidationTypes.TEXT,
                        params: {
                          required: true,
                        },
                      },
                      {
                        name: "position",
                        type: ValidationTypes.OBJECT,
                        params: {
                          required: true,
                          allowedKeys: [
                            {
                              name: "x",
                              type: ValidationTypes.NUMBER,
                              params: {
                                required: true,
                              },
                            },
                            {
                              name: "y",
                              type: ValidationTypes.NUMBER,
                              params: {
                                required: true,
                              },
                            },
                            {
                              name: "z",
                              type: ValidationTypes.NUMBER,
                              params: {
                                required: true,
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              },
            },
          },
          {
            propertyName: "isVisible",
            label: "Visible",
            helpText: "Controls the visibility of the widget",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
        ],
      },
    ];
  }

  getPageView() {
    return (
      <RendererComponent
        defaultSelectedMarkerId={this.props.defaultSelectedMarkerId}
        defaultSelectedNodesIds={this.props.defaultSelectedNodesIds}
        generalStyles={this.props.generalStyles}
        markers={this.props.markers}
        markersStyle={this.props.markerStyles}
        modelSceneGraphUrl={this.props.modelSceneGraphUrl}
        modelStyles={this.props.modelStyles}
        onMarkerSelected={this.updateSelectedMarkerId}
        onNodeSelectionChange={this.updateSelectedNodesIds}
        pills={this.props.pills}
        widgetId={this.props.widgetId}
      />
    );
  }

  static getWidgetType(): string {
    return "RENDERER_WIDGET";
  }
}

export default RendererWidget;
