import React, { useEffect, useRef } from "react";

import * as go from "gojs";
import { ReactDiagram } from "gojs-react";
import { isNil } from "lodash";

import "./styles.css";
import type {
  GraphWidgetHierarchicalModeDirection,
  GraphWidgetHierarchicalModeLayering,
  GraphWidgetMode,
} from "../widget/types";
import { getDiagramLayout } from "./layout";
import { Toolbar } from "./Toolbar";

export type NodeId = string | number;
export type EdgeId = string | number;

export interface Graph {
  nodes: Node[];
  edges: Edge[];
  selectedNodeIds: NodeId[];
}

export interface Node {
  id: NodeId;
  label: string;
  color?: string;
}

export interface Edge {
  id: EdgeId;
  label?: string;
  from: NodeId;
  to: NodeId;
}

// props passed in from a parent component holding state, some of which will be passed to ReactDiagram
interface GraphComponentProps {
  graph: Graph;

  gojsLicenseKey?: string;

  nodeFigure?: "Circle" | "RoundedRectangle";

  nodeTextColor?: string;
  nodeColor?: string;
  edgeColor?: string;
  backgroundColor?: string;
  nodeBorderColor?: string;

  nodeDiameter?: number;
  nodeTextSize?: number;
  nodeBorderSize?: number;

  selectedNodeBorderColor?: string;
  selectedNodeBorderSize?: number;

  onAddNodeToSelection?: (nodeId: NodeId) => void;
  onRemoveNodeFromSelection?: (nodeId: NodeId) => void;
  onClearSelection?: () => void;

  mode: GraphWidgetMode;
  hierarchicalModeDirection: GraphWidgetHierarchicalModeDirection;
  hierarchicalModeLayering: GraphWidgetHierarchicalModeLayering;
  hierarchicalModeLayerSpacing: number;
  hierarchicalModeColumnSpacing: number;
  hierarchicalModeAlignOption: number;

  hasToolbar: boolean;
}

export const GraphComponent: React.FC<GraphComponentProps> = ({
  backgroundColor = "white",
  edgeColor = "black",
  hasToolbar,
  nodeBorderColor = "black",
  nodeBorderSize = 0,
  nodeColor = "blue",
  nodeDiameter = 60,
  nodeFigure = "Circle",
  nodeTextColor = "black",
  nodeTextSize = 10,
  selectedNodeBorderColor = "#4ade80",
  selectedNodeBorderSize = 2,

  ...props
}) => {
  const diagramRef = useRef<ReactDiagram>(null);

  const diagram = diagramRef.current?.getDiagram();

  const zoomToFit = () => {
    const diagram = diagramRef.current?.getDiagram();

    if (!diagram?.scale) {
      return;
    }

    diagram?.commandHandler.zoomToFit();
  };

  useEffect(() => {
    const diagram = diagramRef.current?.getDiagram();

    if (isNil(diagram)) {
      return;
    }

    const selectedNodes = props.graph.selectedNodeIds.reduce((acc, nodeId) => {
      const node = diagram.findNodeForKey(nodeId);
      if (node) {
        acc.push(node);
      }
      return acc;
    }, [] as go.Node[]);

    if (selectedNodes.length === 0) {
      diagram.clearSelection();
      return;
    }
    diagram.selectCollection(selectedNodes);
  }, [props.graph.selectedNodeIds]);

  useEffect(() => {
    const diagram = diagramRef.current?.getDiagram();
    if (isNil(diagram)) {
      return;
    }
    const transaction = "update graph layout";
    diagram.startTransaction(transaction);

    diagram.layout = getDiagramLayout(
      props.mode,
      props.hierarchicalModeDirection,
      props.hierarchicalModeLayering,
      props.hierarchicalModeLayerSpacing,
      props.hierarchicalModeColumnSpacing,
      props.hierarchicalModeAlignOption,
    );

    diagram.commitTransaction(transaction);
  }, [
    props.mode,
    props.hierarchicalModeDirection,
    props.hierarchicalModeLayering,
    props.hierarchicalModeLayerSpacing,
    props.hierarchicalModeColumnSpacing,
    props.hierarchicalModeAlignOption,
  ]);

  useEffect(() => {
    zoomToFit();
  }, [props.graph.nodes, props.graph.edges]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "relative",
      }}
    >
      <ReactDiagram
        divClassName="border"
        initDiagram={makeInitDiagram(props)}
        linkDataArray={props.graph.edges.map((edge) => ({
          ...edge,
          labelBackground: edge.label ? backgroundColor : "rgba(0, 0, 0, 0)",
        }))}
        modelData={{
          nodeColor: nodeColor,
          nodeTextColor: nodeTextColor,
          edgeColor: edgeColor,
          nodeDiameter: nodeDiameter,
          nodeTextWidth: (nodeDiameter * 2) / 3,
          nodeFont: `bold ${nodeTextSize}pt helvetica, bold arial, sans-serif`,
          backgroundColor: backgroundColor,
          nodeFigure: nodeFigure,
          nodeBorderSize: nodeBorderSize,
          nodeBorderColor: nodeBorderColor,
          selectedNodeBorderColor: selectedNodeBorderColor,
          selectedNodeBorderSize: selectedNodeBorderSize,
        }}
        nodeDataArray={props.graph.nodes.map((node) => ({
          ...node,
          color: node.color || nodeColor,
        }))}
        ref={diagramRef}
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: backgroundColor,
        }}
      />

      {hasToolbar && (
        <Toolbar
          onZoomIn={() => {
            diagram?.commandHandler.increaseZoom();
          }}
          onZoomOut={() => {
            diagram?.commandHandler.decreaseZoom();
          }}
          onZoomToFit={zoomToFit}
        />
      )}
    </div>
  );
};

const makeInitDiagram =
  (props: Omit<GraphComponentProps, "hasToolbar">) => () => {
    return initDiagram(props);
  };

const initDiagram = (
  props: Omit<GraphComponentProps, "hasToolbar">,
): go.Diagram => {
  const $ = go.GraphObject.make;
  const layout = getDiagramLayout(
    props.mode,
    props.hierarchicalModeDirection,
    props.hierarchicalModeLayering,
    props.hierarchicalModeLayerSpacing,
    props.hierarchicalModeColumnSpacing,
    props.hierarchicalModeAlignOption,
  );
  go.Diagram.licenseKey = props.gojsLicenseKey || "";
  const diagram = $(go.Diagram, {
    initialAutoScale: go.Diagram.Uniform,
    layout,
    model: $(go.GraphLinksModel, {
      nodeKeyProperty: "id",
      linkKeyProperty: "id",
    }),
    // do an extra layout at the end of a move
    SelectionMoved: (e) => e.diagram.layout.invalidateLayout(),
    BackgroundSingleClicked: () => {
      props.onClearSelection?.();
    },
  });

  diagram.nodeTemplate = $(
    go.Node,
    "Auto",
    {
      // node tooltip
      toolTip: $("ToolTip", $(go.TextBlock, new go.Binding("text", "label"))),
      click: (e, node) => {
        if (
          isNil(props.onAddNodeToSelection) ||
          isNil(props.onClearSelection) ||
          isNil(props.onRemoveNodeFromSelection)
        ) {
          return;
        }

        const isPart = node instanceof go.Part;
        if (!isPart) return;

        const nodeId = node.key as NodeId;
        const isCtrlPressed = e.diagram.lastInput.control;

        if (isCtrlPressed) {
          if (node.isSelected) {
            props.onAddNodeToSelection?.(nodeId);
          } else {
            props.onRemoveNodeFromSelection?.(nodeId);
          }
        } else {
          props.onClearSelection?.();
          props.onAddNodeToSelection?.(nodeId);
        }
      },
    },
    // node shape settings
    $(
      go.Shape,
      new go.Binding("fill", "color"),
      new go.Binding("width", "nodeDiameter").ofModel(),
      new go.Binding("height", "nodeDiameter").ofModel(),
      new go.Binding("figure", "nodeFigure").ofModel(),
      new go.Binding("strokeWidth", "nodeBorderSize").ofModel(),
      new go.Binding("stroke", "nodeBorderColor").ofModel(),
    ),
    // node text settings
    $(
      go.TextBlock,
      {
        textAlign: "center",
        overflow: go.TextBlock.OverflowEllipsis,
        maxLines: 3,
      },
      new go.Binding("text", "label"),
      new go.Binding("stroke", "nodeTextColor").ofModel(),
      new go.Binding("width", "nodeTextWidth").ofModel(),
      new go.Binding("font", "nodeFont").ofModel(),
    ),
    // selected node settings
    {
      selectionAdornmentTemplate: $(
        go.Adornment,
        "Auto",
        $(
          go.Shape,
          { fill: null },
          new go.Binding("figure", "nodeFigure").ofModel(),
          new go.Binding("width", "nodeDiameter").ofModel(),
          new go.Binding("height", "nodeDiameter").ofModel(),
          new go.Binding("stroke", "selectedNodeBorderColor").ofModel(),
          new go.Binding("strokeWidth", "selectedNodeBorderSize").ofModel(),
        ),
        $(go.Placeholder),
      ),
    },
  );

  diagram.linkTemplate = $(
    go.Link,
    // edge shape settings
    { routing: go.Link.OrientAlong, layerName: "Background" },
    $(go.Shape, new go.Binding("stroke", "edgeColor").ofModel()),
    $(
      go.Shape, // the arrowhead
      {
        toArrow: "OpenTriangle",
      },
      new go.Binding("stroke", "edgeColor").ofModel(),
    ),
    // edge label settings
    $(
      go.Panel,
      "Auto",
      $(
        go.Shape,
        {
          stroke: null,
        },
        new go.Binding("fill", "labelBackground"),
      ),
      $(
        go.TextBlock,
        {
          textAlign: "center",
          font: "10pt helvetica, arial, sans-serif",
          margin: 4,
        },
        new go.Binding("stroke", "edgeColor"),
        new go.Binding("text", "label"),
      ),
    ),
  );

  return diagram;
};
