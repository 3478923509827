import type { Pill } from "widgets/RendererWidget/widget/types";

import type { PillInputData as PillInternal } from "./types";

/**
 * Generate `pills` argument for `usePills` hook from widget input
 */
export const buildPillsList = (pillsList: Pill[]) => {
  const pills = pillsList.map(
    (pill) =>
      ({
        id: pill.id,
        label: pill.label,
        coordinates: [pill.position.x, pill.position.y, pill.position.z],
      } as PillInternal),
  );

  return pills;
};
