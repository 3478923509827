import React from "react";

import styled from "styled-components";

import FocusIcon from "./icons/Focus";
import ResetIcon from "./icons/Reset";

import { Tooltip } from "./Tooltip";

interface ToolbarProps {
  onFocus: () => void;
  onReset: () => void;
}

const Toolbar: React.FC<ToolbarProps> = ({ onFocus, onReset }) => (
  <Container>
    <Tooltip content="Focus (F)">
      <Button onClick={onFocus}>
        <FocusIcon />
      </Button>
    </Tooltip>
    <Tooltip content="Reset (R)">
      <Button onClick={onReset}>
        <ResetIcon />
      </Button>
    </Tooltip>
  </Container>
);

export default Toolbar;

const Container = styled.div`
  /* Layout */
  display: flex;
  align-items: flex-start;
  gap: 4px;

  /* Position */
  position: absolute;
  right: 10px;
  top: 10px;

  border-radius: 6px;
  border: 1px solid #e0dede;
  background: #fff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1),
    0px 1px 2px 0px rgba(0, 0, 0, 0.06);
`;

const Button = styled.button`
  /* Layout */
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 8px;
  border-radius: 6px;
  color: #485162;

  &:hover {
    background: rgba(89, 100, 120, 0.08);
  }
`;
